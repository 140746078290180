export const functions = [
  {
    key: "Off",
    value:
      "This indicates that using the control will disconnect power to the device.",
  },

  {
    key: "Cotton",
    value:
      "For cleaning normal to heavily soiled cotton or linen clothing maximum load 10 kg/5*** kg Cold - 90 °C 400 - 1400 rpm",
  },

  {
    key: "Pre wash",
    value: "Pre-wash setting is done for heavily soiled laundry",
  },

  {
    key: "Synthetics",
    value:
      "For cleaning easy-care laundry made from synthetic fibres or mixed fabrics, such as polyamide or polyester. Max. load 4 kg Cold - 60 °C 400 - 1200 rpm",
  },

  {
    key: "Mix",
    value:
      "For cleaning different fabrics (cotton, synthetic fibres and mixed fabrics) that have particularly strict hygienic requirements using active oxygen. The max. load 4 kg Cold - 40 °C 400 - 1400 rpm",
  },

  {
    key: "Delicates",
    value:
      "For cleaning delicate fabrics, such as silk, satin or viscose. Reduced spinning for optimal textile care. We recommend using a detergent designed for delicates. Max. load 2 kg Cold - 40 °C Off - 800 rpm",
  },
  {
    key: "Wool",
    value:
      "For gently cleaning woollen fabrics, e.g. cashmere/lamb's wool. Reduced drum movement prevents fibre rubbing and shrinking. Very gentle, suitable for fabrics which hand-washing is recommended. Detergent designed for woollens strongly recommended. Max. load 2 kg Cold - 40 °C Off - 800 rpm",
  },

  {
    key: "Drum Clean",
    value:
      "Drum Clean is a cleaning cycle designed to remove detergent residue and build up. ... The Drum Clean cycle should be run every forty washes. To use the Drum Cycle, press the Power button, turn the cycle selector to ‘Drum Clean', then press the Start/Pause button",
  },
  {
    key: "Rinse",
    value:
      "Washing clothes with clean water to remove soap is called rinsing. The rinse cycle uses intervals of spraying and spinning to force the soapy water and detergent out of the washer.",
  },

  {
    key: "Spin/Drain",
    value:
      "The spin cycle works to extract moisture from your clothes at the end of the wash cycle. A pipe connected with the washing machine to empty the soapy solution is draining the machine.",
  },

  {
    key: "Sportswear",
    value:
      "Wash in a cold cycle (30°C is usually the recommended temperature on the labels of these garments)",
  },

  {
    key: "Jeans",
    value:
      "For gentle cleaning of dark-coloured laundry, such as jeans. Wash the laundry inside-out. High water level for an optimal rinse result. Reduced spinning for optimal textile care. We recommend using a liquid detergent. Max. load 3.5 kg Cold - 40 °C 400 - 1200 rpm",
  },
  {
    key: "Blanket",
    value:
      "For gentle cleaning of microfiber blankets, pillows and clothing filled with down or synthetic fibres. Wash larger items individually. We recommend using a small dose of a detergent designed for delicates. Max. load 2.5 kg Cold - 60 °C 400 - 1200 rpm",
  },

  {
    key: "Hygiene",
    value:
      "To have clean, disinfected clothes it is essential to ensure the optimal hygiene and correct functioning of your washing machine.",
  },

  {
    key: "Super Quick Mode",
    value:
      "This mode washes the clothes quicker than the normal cycle, usually taking 15 minutes to an hour.",
  },
];
