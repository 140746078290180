import { render } from "@testing-library/react";
import React from "react";
import { w3cwebsocket as W3CWebSocket } from "websocket";
import Jimp from "jimp";
import Drawer from "react-bottom-drawer";
import { Player, BigPlayButton } from "video-react";
import data from "./data.json";
import { functions } from "./knob";
import SwipeableBottomSheet from "react-swipeable-bottom-sheet";

const client = new W3CWebSocket("wss://dev.ml.xane.ai:3001");

class App extends React.Component {
  constructor(props) {
    super(props);
    this.streamCameraVideo = this.streamCameraVideo.bind(this);
  }
  state = {
    frames: [],
    predictions: [],
    isVisible: false,
    ratio: null,
    intent: "",
  };

  async streamCameraVideo() {
    alert("This app uses your camera to stream input for the demo.");
    let video = document.getElementById("videoMirror");
    let height = window.innerHeight;
    let width = window.innerWidth;
    let aspectRatio = width > height ? width / height : height / width;

    let videoConstraints = {
      audio: false,
      video: {
        facingMode: "environment",
        aspectRatio: aspectRatio,
      },
    };

    await navigator.mediaDevices
      .getUserMedia(videoConstraints)
      .then((stream) => {
        //window.addEventListener("beforeunload", this.gracefulCleanupVideo());
        video.srcObject = stream;
        video.play();
      })
      .catch((e) => {
        alert(e);
      });
  }

  gracefulCleanupVideo = () => {
    let video = document.getElementById("videoMirror");
    video.pause();

    const stream = video.srcObject;
    const tracks = stream.getTracks();

    tracks.forEach(function (track) {
      track.stop();
    });

    video.srcObject = null;
  };

  async componentDidMount() {
    let mainDiv = document.getElementById("xane");
    mainDiv.style.backgroundColor = "#000000";

    this.streamCameraVideo();
    let canvas = document.getElementById("canvas");
    let video = document.getElementById("videoMirror");
    let getPrediction = this.getPrediction.bind(this);
    canvas.addEventListener("mousedown", function (e) {
      let rect = canvas.getBoundingClientRect();
      let x = e.clientX;
      let y = e.clientY;
      let prediction = getPrediction(x, y, 8);
    });
    let capture = this.captureImageAndSend.bind(this);
    let handlePrediction = this.renderPredictions.bind(this);
    let height = window.innerHeight;
    let width = window.innerWidth;

    client.onopen = () => {
      console.log("WebSocket Client Connected");
      capture(width, height);
      // this.captureImageAndSend(width, height);
    };

    client.onmessage = function (e) {
      if (e.data && e.data !== "[]") {
        handlePrediction(JSON.parse(e.data.replace(/\'/g, '"')));
        let canvas = document.getElementById("canvas");
        canvas.style.visibility = "visible";
        return;
      }

      capture(width, height);
    };
  }

  componentWillUnmount = () => {
    this.gracefulCleanupVideo();
  };

  getPrediction = (x, y, r) => {
    let prediction = this.state.predictions.filter(
      (pred) =>
        pred.coordinates.x1 > x - r &&
        pred.coordinates.x1 < x + r &&
        pred.coordinates.y1 > y - r &&
        pred.coordinates.y1 < y + r
    )[0];
    let intent = prediction ? prediction.intent : "No Content Found";

    if (intent == "No Content Found") {
      return;
    } else {
      this.setState({ isVisible: true, intent: intent });
      return;
    }
  };

  renderPredictions = (predictions) => {
    var colorArray = [
      "#FF6633",
      "#FFB399",
      "#FF33FF",
      "#FFFF99",
      "#00B3E6",
      "#E6B333",
      "#3366E6",
      "#999966",
      "#99FF99",
      "#B34D4D",
      "#80B300",
      "#809900",
      "#E6B3B3",
      "#6680B3",
      "#66991A",
      "#FF99E6",
      "#CCFF1A",
      "#FF1A66",
      "#E6331A",
      "#33FFCC",
      "#66994D",
      "#B366CC",
      "#4D8000",
      "#B33300",
      "#CC80CC",
      "#66664D",
      "#991AFF",
      "#E666FF",
      "#4DB3FF",
      "#1AB399",
      "#E666B3",
      "#33991A",
      "#CC9999",
      "#B3B31A",
      "#00E680",
      "#4D8066",
      "#809980",
      "#E6FF80",
      "#1AFF33",
      "#999933",
      "#FF3380",
      "#CCCC00",
      "#66E64D",
      "#4D80CC",
      "#9900B3",
      "#E64D66",
      "#4DB380",
      "#FF4D4D",
      "#99E6E6",
      "#6666FF",
    ];
    this.setState({ predictions: predictions });
    let elem = document.getElementById("canvas");
    const ctx = elem.getContext("2d");

    const font = "bold 12px sans-serif";
    ctx.font = font;
    ctx.textBaseline = "top";
    predictions.forEach((prediction) => {
      let color = colorArray[Math.floor(Math.random() * colorArray.length - 1)];
      const x = prediction.coordinates.x1;
      const y = prediction.coordinates.y1;
      const width = prediction.width;
      const height = prediction.height;

      ctx.strokeStyle = color;
      ctx.lineWidth = 1;
      ctx.beginPath();
      ctx.arc(x, y, width, 0, 2 * Math.PI);
      ctx.fillStyle = color;
      ctx.fill();

      //Draw the label background.
      ctx.fillStyle = color;
      const textWidth = ctx.measureText(prediction.intent).width;
      const textHeight = parseInt(font, 12); // base 10

      const x1 = prediction.coordinates.x1 - 5;
      const y1 = prediction.coordinates.y1 - 20;
      // Draw the text last to ensure it's on top.
      ctx.fillStyle = color;
      ctx.fillText(prediction.intent, x1, y1);
    });
  };

  setFrames = (framesUrls) => {
    this.setState({ frames: framesUrls });
  };

  captureImageAndSend = async (w, h) => {
    const video = document.getElementById("videoMirror");
    const canvas = document.getElementById("canvas");
    const ctx = canvas.getContext("2d");

    ctx.drawImage(video, 0, 0, w, h);

    const data = canvas.toDataURL("image/jpeg", 0.1).split(",")[1];

    if (client.readyState === client.OPEN) {
      client.send(data);
    }
    //
    // let image = document.createElement('img');
    // image.src = canvas.toDataURL("image/jpeg", 0.25);
    // document.body.appendChild(image);
  };

  knob = () => {
    return (
      <>
        <p style={{ color: "white" }}>
          It is a programme selector used for selecting different modes
          according to the requirement one has.
        </p>
        <Player
          autoPlay
          controls={false}
          fluid={false}
          ref={(player) => {
            this.player = player;
          }}
          width={320}
        >
          <source src="https://munna-storage.s3.ap-south-1.amazonaws.com/SIEMENS+Washing+Machine+Use+Case.mp4" />
          <BigPlayButton position="center" />
        </Player>
        <ul>
          The various modes are -
          {functions.map((m, i) => (
            <li style={{ margin: 10, padding: 5 }}>
              <span style={{ fontWeight: "bold", color: "#fff", fontSize: 20 }}>
                {m.key}
              </span>{" "}
              - <span style={{ color: "#fff", fontSize: 18 }}>{m.value}</span>
            </li>
          ))}
        </ul>
      </>
    );
  };

  renderOtherFunctions = (programme) => {
    return (
      <p style={{ lineHeight: 1.5, padding: 10, width: "70%", color: "#fff" }}>
        {data[programme]}
      </p>
    );
  };

  finishIn = () => {
    return (
      <p style={{ lineHeight: 1.5, padding: 10, width: "70%", color: "#fff" }}>
        Finish In - Before starting the programme, you can preselect the
        programme end ("Ready in" time) in hourly increments (h = hour) up to a
        maximum of 24 hours. To do this: 1. Select a programme. The programme
        duration is displayed for the selected programme, e.g. 2:30
        (hours:minutes). 2. Press the Finish in button repeatedly until the
        required number of hours is displayed. Note: The setting values are
        increased using the Finish in + button, and decreased using the Finish
        in - button. 3. Press the Ü button. The programme starts. The selected
        number of hours (e.g. 8 h) is shown in the display panel and counts down
        until the wash programme starts. The duration of the programme is then
        displayed. Note: The length of the programme is automatically adjusted
        when the programme is running. Changes to the default programme settings
        or programme settings may also change the programme duration. After
        starting the programme (if a Finish in time has been selected and the
        wash programme has not yet started), you can change the preselected
        number of hours as follows: 1. Press the Ü button. 2. Use the Finish in
        - or + button to change the number of hours. 3. Press the Ü button.
        After starting the programme, you can add or remove laundry if
        necessary.
      </p>
    );
  };

  render() {
    return (
      <>
        <div id="xane">
          <video
            id="videoMirror"
            autoPlay={true}
            height={window.innerHeight}
            width={window.innerWidth}
            playsInline
            muted={true}
          ></video>
          <canvas
            id="canvas"
            height={window.innerHeight}
            width={window.innerWidth}
            style={{ position: "fixed", top: 0, left: 0, visibility: "hidden" }}
          ></canvas>
          <Drawer
            isVisible={this.state.isVisible}
            onClose={() => this.setState({ isVisible: false })}
            hideScrollbars={true}
          >
            <div
              style={{
                // minHeight: "100vh",
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                overflowY: "hidden",
              }}
            >
              <p style={{ fontWeight: "bolder", fontSize: 22, color: "#fff" }}>
                {this.state.intent}
              </p>
              {this.state.intent.toLowerCase() == "knob"
                ? this.knob()
                : this.state.intent == "Finish In"
                ? this.finishIn()
                : this.renderOtherFunctions(this.state.intent.toLowerCase())}
            </div>
          </Drawer>
        </div>
      </>
    );
  }
}

export default App;
